// @ts-ignore
import createStellarIdenticon from "stellar-identicon-js";

const Identicon = ({ accountId }: { accountId: string }) => {
  const canvas = createStellarIdenticon(accountId);
  const renderedIcon = canvas.toDataURL();
  return (
    <div className="h-8 w-8 border-gray-dark flex content-center justify-center rounded-full background-gray-base">
      <img className="h-5 w-5" src={renderedIcon} alt="Account Icon" />
    </div>
  );
};

export default Identicon;

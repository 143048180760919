export const truncateAddress = (accountId: string) =>
  accountId.slice(0, 5) + "..." + accountId.slice(-5);

export const classnames = (...classes: string[]) =>
  classes.filter(Boolean).join(" ");

export const round = (stringNumber: string) =>
  parseFloat(stringNumber).toFixed(2);

export const isObjEmpty = (obj: any) => Object.keys(obj).length === 0;

import useWindowSize from "./useWindowSize";

function useGridColumns() {
  const size = useWindowSize();
  const isLg = size.width && size.width > 768;

  const mobileGridCols = "grid-cols-1";
  const desktopGridCols = "grid-cols-2";

  const gridCols = isLg ? desktopGridCols : mobileGridCols;

  return gridCols;
}

export default useGridColumns;

import Header from "./Header";
import Main from "./Main";
import Footer from "./Footer";

const Wrapper = (props: { children: React.ReactNode }) => (
  <div className="font-regular flex flex-col background-gray-base h-full min-h-screen">
    <Header />
    <Main>{props.children}</Main>
    <Footer />
  </div>
);

export default Wrapper;

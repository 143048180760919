import Identicon from "../Shared/Identicon";
import { truncateAddress } from "../../utils/utils";
import { Link } from "react-router-dom";
import { findNativeBalance } from "../../utils/detailsUtils";
import { round } from "../../utils/utils";
import { BalanceType } from "../../types/types";

const Account = ({
  accountId,
  balances,
}: {
  accountId: string;
  balances: BalanceType[];
}) => {
  const { balance } = findNativeBalance(balances) as BalanceType;
  const roundedNativeBalance = round(balance);

  return (
    <Link
      to={`details/${accountId}`}
      className="p-2 border-gray-dark flex text-base background-white rounded-lg cursor-pointer text-decoration-none text-black"
    >
      <Identicon accountId={accountId} />
      <div className="flex content-center ml-2">
        <p className="text-inherit">{truncateAddress(accountId)}</p>
      </div>
      <div className="flex content-center ml-auto">
        <p className="font-bold text-inherit">{roundedNativeBalance} XLM</p>
      </div>
    </Link>
  );
};

export default Account;

import { fetchAccounts } from "../../api/fetchAccounts";

import Account from "./Account";
import Button from "../Shared/Button";
import Loader from "../Shared/Loader";
import useGridColumns from "../../hooks/useGridColumns";
import { classnames } from "../../utils/utils";

import { useAppSelector, useAppDispatch } from "../../redux/hooks";

import {
  addAccounts,
  setIsLoading,
  clearAccounts,
} from "../../redux/accountsSlice";
import { clearSingleAccount } from "../../redux/singleAccountSlice";

import {
  AccountType,
  BalanceResponseType,
  AccountResponseType,
} from "../../types/types";

import { useEffect } from "react";

const Landing = () => {
  const accounts = useAppSelector((state) => state.accounts.values);
  const isLoading = useAppSelector((state) => state.accounts.isLoading);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(clearSingleAccount());
    dispatch(clearAccounts());
  }, []);

  const handleClick = async () => {
    dispatch(setIsLoading(true));
    const accountsData = await fetchAccounts();

    const mappedAccountsData: AccountType[] = accountsData.map(
      (accounts: AccountResponseType) => ({
        accountId: accounts.account_id,
        balances: accounts.balances.map((balance: BalanceResponseType) => ({
          balance: balance.balance,
          assetType: balance.asset_type,
        })),
      }),
    );

    dispatch(addAccounts(mappedAccountsData));
    dispatch(setIsLoading(false));
  };

  const accountsLength = accounts.length;

  const gridColumns = useGridColumns();

  return (
    <>
      <div className="mb-6">
        <p className="text-base">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vulputate
          mauris id metus tincidunt sagittis. Ut vestibulum, odio in aliquam
          ornare, est quam sagittis dui, id cursus lorem elit vel massa. In ut
          suscipit sapien, eu feugiat ipsum. Aliquam et mattis ex. Phasellus
          cursus tellus ac mi suscipit, vitae suscipit mauris efficitur.
        </p>
      </div>
      <div className="flex justify-center mb-6">
        <Button onClick={handleClick}>Show Accounts</Button>
      </div>
      <div className="mb-6">
        <h2 className="font-bold text-lg">All Accounts ({accountsLength})</h2>
      </div>
      {isLoading ? (
        <div className="w-full flex justify-center content-center">
          <Loader />
        </div>
      ) : (
        <div className={classnames("grid gap-4", gridColumns)}>
          {accountsLength > 0 &&
            accounts.map(({ accountId, balances }) => (
              <Account
                accountId={accountId}
                balances={balances}
                key={accountId}
              />
            ))}
        </div>
      )}
    </>
  );
};

export default Landing;

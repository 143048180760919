import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "./store";
import { AccountType } from "../types/types";

interface AccountsState {
  values: AccountType[];
  isLoading: boolean;
}

const initialState: AccountsState = {
  values: [],
  isLoading: false,
};

export const accountsSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    addAccounts(state, action: PayloadAction<AccountType[]>) {
      state.values = action.payload;
    },
    clearAccounts() {
      return initialState;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
  },
});

export const { addAccounts, clearAccounts, setIsLoading } =
  accountsSlice.actions;

export const selectAccounts = (state: RootState) => state.accounts.values;

export default accountsSlice.reducer;

import { useEffect } from "react";
import { fetchSingleAccount } from "../../api/fetchSingleAccount";
import { round } from "../../utils/utils";
import { useAppDispatch } from "../../redux/hooks";
import { getAssetCode } from "../../utils/detailsUtils";
import { addHomeDomain } from "../../redux/singleAccountSlice";
import Loader from "../Shared/Loader";

const getHomeDomain = (homeDomain: string | undefined, isNative: boolean) => {
  if (homeDomain) return homeDomain;
  if (isNative) return "native";
  return "---";
};

const Balance = ({
  balance,
  assetCode,
  assetIssuer,
  assetType,
  reservedXlmBalance,
  homeDomain,
}: {
  balance: string;
  assetCode: string | undefined;
  assetIssuer: string;
  assetType: string;
  reservedXlmBalance?: number;
  homeDomain?: string;
}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchData = async () => {
      let homeDomain = "---";
      if (assetType === "native") homeDomain = "native";

      if (assetIssuer) {
        const { home_domain } = await fetchSingleAccount(assetIssuer);
        if (home_domain) homeDomain = home_domain;
      }

      dispatch(
        addHomeDomain({
          assetCode,
          assetType,
          homeDomain,
        }),
      );
    };

    fetchData();
  }, []);

  const isNative = assetType === "native";
  const roundedBalance = round(balance);
  const noramlizedAssetCode = getAssetCode(assetCode);
  const showXlmNativeBalance = isNative && reservedXlmBalance !== undefined;

  const xlmNativeBalance = showXlmNativeBalance
    ? +balance - +reservedXlmBalance
    : undefined;

  const roundedXlmNativeBalance = round(String(xlmNativeBalance));

  const showLoader = !isNative && !homeDomain;

  return (
    <div className="p-2 border-gray-dark flex text-base background-white rounded-lg">
      <div className="flex content-center ml-2">
        <p className="font-bold">
          {roundedBalance} {noramlizedAssetCode}
        </p>
        {showXlmNativeBalance && (
          <p className="ml-2">
            ({roundedXlmNativeBalance} {noramlizedAssetCode})
          </p>
        )}
      </div>
      <div className="flex content-center ml-auto">
        {showLoader ? (
          <Loader small />
        ) : (
          <p className="text-sm text-gray-dark">
            {getHomeDomain(homeDomain, isNative)}
          </p>
        )}
      </div>
    </div>
  );
};

export default Balance;

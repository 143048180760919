import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "./store";
import { SingleAccountType } from "../types/types";

interface SingleAccountState {
  value: SingleAccountType;
  isLoading: boolean;
}

const initialState: SingleAccountState = {
  value: {} as SingleAccountType,
  isLoading: false,
};

export const singleAccountSlice = createSlice({
  name: "singleAccount",
  initialState,
  reducers: {
    addSingleAccount(state, action: PayloadAction<SingleAccountType>) {
      state.value = action.payload;
    },
    clearSingleAccount() {
      return initialState;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    addHomeDomain(
      state,
      action: PayloadAction<{
        assetCode: string | undefined;
        assetType: string | undefined;
        homeDomain: string;
      }>,
    ) {
      const newBalances = [...state.value.balances];

      const index = newBalances.findIndex(({ assetType, assetCode }) => {
        if (!assetCode) return assetType === action.payload.assetType;
        return assetCode === action.payload.assetCode;
      });

      newBalances[index].homeDomain = action.payload.homeDomain;

      state.value.balances = newBalances;
    },
  },
});

export const {
  addSingleAccount,
  clearSingleAccount,
  setIsLoading,
  addHomeDomain,
} = singleAccountSlice.actions;

export const selectSingleAccount = (state: RootState) =>
  state.singleAccount.value;

export default singleAccountSlice.reducer;

import Logo from "../../resources/logo.svg";

const Header = () => (
  <nav className="flex space-between p-6 border-b-gray-dark ml-6 mr-6">
    <img src={Logo} className="h-8" alt="Stellar Logo" />
    <div className="flex content-center">
      <h1 className="text-primary text-lg font-bold">Account Explorer</h1>
    </div>
  </nav>
);

export default Header;

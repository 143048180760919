import React from "react";
import { classnames } from "../../utils/utils";

const Button = ({
  children,
  onClick,
  primary = true,
}: {
  children: React.ReactNode;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  primary?: boolean;
}) => {
  const primaryClasses = "text-white border-none background-primary";

  const secondaryClasses = "text-primary border-primary bg-transparent";

  const classes = classnames(
    "text-sm rounded p-2 cursor-pointer font-bold",
    primary ? primaryClasses : secondaryClasses,
  );

  return (
    <button className={classes} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;

import useWindowSize from "./useWindowSize";
import { truncateAddress } from "../utils/utils";

function useFormattedAccountId(accountId: string) {
  const size = useWindowSize();
  const isLg = size.width && size.width > 768;
  const formattedAccountId = isLg ? accountId : truncateAddress(accountId);
  return formattedAccountId;
}

export default useFormattedAccountId;

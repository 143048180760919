const DetailsGridRow = ({
  title,
  amount,
}: {
  title: string;
  amount: string;
}) => (
  <div className="flex">
    <p>{title}</p>
    <div className="flex content-center ml-auto">
      <p className="font-bold">{amount}</p>
    </div>
  </div>
);

export default DetailsGridRow;

import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Wrapper from "./components/Shared/Wrapper";
import Landing from "./components/Landing/Landing";
import Details from "./components/Details/Details";
import { store } from "./redux/store";
import { Provider } from "react-redux";

import "./styles/reset.scss";
import "./styles/colors.scss";

import "./styles/loader.scss";

import "./styles/background.scss";
import "./styles/border.scss";
import "./styles/flex.scss";
import "./styles/font.scss";
import "./styles/grid.scss";
import "./styles/height.scss";
import "./styles/margin.scss";
import "./styles/padding.scss";
import "./styles/rounded.scss";
import "./styles/text.scss";
import "./styles/width.scss";
import "./styles/cursor.scss";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
    errorElement: <Landing />,
  },
  {
    path: "details/:accountId",
    element: <Details />,
    errorElement: <Landing />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Wrapper>
        <RouterProvider router={router} />
      </Wrapper>
    </Provider>
  </React.StrictMode>,
);

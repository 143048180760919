import { useEffect } from "react";
import { fetchSingleAccount } from "../../api/fetchSingleAccount";
import { useParams, useNavigate } from "react-router-dom";
import Loader from "../Shared/Loader";
import Button from "../Shared/Button";
import Identicon from "../Shared/Identicon";
import Balance from "./Balance";
import DetailsGridRow from "./DetailsGridRow";
import {
  getReservedXmlBalance,
  getSellingLiabilities,
  filterBalances,
} from "../../utils/detailsUtils";
import {
  BalanceType,
  SingleAccountType,
  BalanceResponseType,
} from "../../types/types";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { addSingleAccount, setIsLoading } from "../../redux/singleAccountSlice";
import { classnames, isObjEmpty } from "../../utils/utils";
import useFormattedAccountId from "../../hooks/useFormattedAccountId";
import useGridColumns from "../../hooks/useGridColumns";
import useWindowSize from "../../hooks/useWindowSize";

const Details = () => {
  const singleAccount = useAppSelector((state) => state.singleAccount.value);
  const singleAccountIsLoading = useAppSelector(
    (state) => state.accounts.isLoading,
  );
  const dispatch = useAppDispatch();

  let { accountId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setIsLoading(true));
      const data = await fetchSingleAccount(accountId || "");

      const mappedBalances: BalanceType[] = data.balances.map(
        (balance: BalanceResponseType) => ({
          balance: balance.balance,
          assetCode: balance.asset_code,
          assetType: balance.asset_type,
          assetIssuer: balance.asset_issuer,
          sellingLiabilities: balance.selling_liabilities,
          homeDomain: "",
        }),
      );

      const mappedAccountData: SingleAccountType = {
        sequence: data.sequence,
        signers: data.signers,
        balances: mappedBalances,
        subentryCount: data.subentry_count,
        numSponsored: data.num_sponsored,
        numSponsoring: data.num_sponsoring,
      };

      dispatch(addSingleAccount(mappedAccountData));
      dispatch(setIsLoading(false));
    };

    fetchData().catch(console.error);
  }, []);

  const {
    sequence,
    signers,
    balances,
    subentryCount,
    numSponsored,
    numSponsoring,
  } = singleAccount;

  const filteredBalances = filterBalances(balances as BalanceType[]);
  const filteredBalancesLength = filteredBalances?.length || 0;
  const sellingLiabilities = getSellingLiabilities(balances);

  const reservedXlmBalance = getReservedXmlBalance(
    +subentryCount,
    +numSponsoring,
    +numSponsored,
    sellingLiabilities !== undefined ? +sellingLiabilities : 0,
  );

  const reservedXlmString = `${reservedXlmBalance} XLM`;

  const rows = [
    { title: "Sequence", amount: sequence },
    { title: "Balances Count", amount: balances?.length },
    { title: "Signers Count", amount: signers?.length },
    { title: "Sub-entry Count", amount: subentryCount },
    { title: "Sponsoring Accounts Count", amount: numSponsoring },
    { title: "Sponsored Accounts Count", amount: numSponsored },
    { title: "Reserved XLM Balance", amount: reservedXlmString },
  ];

  const isEmptyAccount = isObjEmpty(singleAccount);

  const gridColumns = useGridColumns();
  const size = useWindowSize();
  const isLg = size.width && size.width > 768;

  const formattedAccountId = useFormattedAccountId(accountId || "");

  return (
    <>
      <div className="mb-6 flex flex-col">
        <div className="flex w-full mb-6">
          <h2 className="font-bold text-lg">Account Details</h2>
          <div className="ml-auto">
            <Button
              onClick={() => navigate("/", { replace: true })}
              primary={false}
            >
              Go back
            </Button>
          </div>
        </div>
        {singleAccountIsLoading || isEmptyAccount ? (
          <div className="w-full flex justify-center content-center">
            <Loader />
          </div>
        ) : (
          <div className="p-4 border-gray-dark flex flex-col text-base background-white rounded-lg cursor-pointer">
            <div className="flex w-full">
              <Identicon accountId={accountId || ""} />
              <div className="ml-2 flex content-center">
                <p>{formattedAccountId}</p>
              </div>
            </div>
            <div
              className={classnames(
                "grid gap-2 mt-2 gap-x-12",
                isLg ? "grid-flow-col grid-rows-4" : "",
              )}
            >
              {rows.map(({ title, amount }) => (
                <DetailsGridRow
                  title={title}
                  amount={String(amount)}
                  key={title}
                />
              ))}
            </div>
          </div>
        )}
      </div>

      <div className="mb-6">
        <h2 className="font-bold text-lg">
          Account Balances ({filteredBalancesLength})
        </h2>
      </div>
      <div className={classnames("grid gap-4", gridColumns)}>
        {filteredBalancesLength > 0 &&
          filteredBalances
            ?.sort((a, b) => {
              if (a.assetType === "native") return -1;
              if (b.assetType === "native") return 1;
              return 0;
            })
            .map((balance, index) => {
              return (
                <Balance
                  key={index}
                  balance={balance.balance}
                  assetCode={balance.assetCode || ""}
                  assetIssuer={balance.assetIssuer || ""}
                  assetType={balance.assetType}
                  reservedXlmBalance={reservedXlmBalance}
                  homeDomain={balance.homeDomain}
                />
              );
            })}
      </div>
    </>
  );
};

export default Details;

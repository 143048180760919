import { BalanceType } from "../types/types";

export const getAssetCode = (asset_code: string | undefined) => {
  if (asset_code) return asset_code;
  return "XLM";
};

export const getReservedXmlBalance = (
  subentryCount: number,
  numSponsoring: number,
  numSponsored: number,
  sellingLiabilities: number = 0,
) =>
  (2 + subentryCount + numSponsoring - numSponsored) * 0.5 + sellingLiabilities;

export const assetTypes = ["native", "credit_alphanum4", "credit_alphanum12"];

export const filterBalances = (balances: BalanceType[]) =>
  balances?.filter((balance: BalanceType) =>
    assetTypes.includes(balance.assetType),
  );

export const findNativeBalance = (balances: BalanceType[]) =>
  balances.find((balance: BalanceType) => balance.assetType === "native");

export const getSellingLiabilities = (balances: BalanceType[]) => {
  if (!balances) return;
  const xlmBalance = findNativeBalance(balances);
  return xlmBalance?.sellingLiabilities;
};
